<script setup lang="ts">

interface Props {
  amount: string,
  symbol: string
}

const props = defineProps<Props>()

const { formatNumber } = useFormatNumber()
const formattedAmount = computed(() => formatNumber(props.amount))
</script>

<template>
  <span class="text-body-bold-12">
    {{ formattedAmount }}
    <span class="text-body-regular-12 text-black-50">
      {{ symbol }}
    </span>
  </span>
</template>

<style scoped lang="postcss">

</style>
